export enum ProgressActionTypeConst {
    CHANGE_IS_COMPLETED = '@@Progress/CHANGE_IS_COMPLETED',
    CHANGE_PROGRESS = '@@Progress/CHANGE_PROGRESS',
    CHANGE_IS_LOADING = '@@Progress/CHANGE_IS_LOADING',
    CHANGE_CANCEL_CURRENT_WORK = '@@Progress/CHANGE_CANCEL_CURRENT_WORK',
    CHANGE_CLEAR = '@@Progress/CHANGE_CLEAR',
    CHANGE_BACKEND_STATS = '@@Progress/CHANGE_BACKEND_STATS',
    CHANGE_LOAD_ALL_DATA = '@@Progress/CHANGE_LOAD_ALL_DATA',
    CHANGE_PROGRESS_SAVE_LOAD_BACKEND = '@@Progress/CHANGE_PROGRESS_SAVE_LOAD_BACKEND',
}
